import jQuery from 'jquery'
window.$ = window.jQuery = jQuery

// workaround to move tracking to anchor from shortcode element
const trackedShortcodes = document.querySelectorAll('a [data-tracking]')

Array.from(trackedShortcodes).forEach(trackedShortcode => {
  const parent = trackedShortcode.parentElement
  parent.setAttribute(
    'data-tracking',
    trackedShortcode.getAttribute('data-tracking')
  )
  trackedShortcode.removeAttribute('data-tracking')
})

// tracking for virtual page changes
const customPageTrackingElements = document.querySelectorAll(
  '[data-page-tracking]'
)

Array.from(customPageTrackingElements).forEach(customPageTrackingElement => {
  customPageTrackingElement.addEventListener('click', e => {
    const trackingString = customPageTrackingElement.getAttribute(
      'data-page-tracking'
    )
    utag.data['aaPageName'] = trackingString
    jQuery(window).trigger('page_change', {})
  })
})

const populateHutk = () => {
  const hutk = getCookie('hubspotutk')
  if (hutk) {
    const hutkFields = [...document.querySelectorAll('input[name="hutk"]')]
    hutkFields.forEach(hutkField => {
      hutkField.value = hutk
    })
  }
}
populateHutk()

function getCookie(name) {
  let cookie = {}
  document.cookie.split(';').forEach(function(el) {
    let [k, v] = el.split('=')
    cookie[k.trim()] = v
  })
  return cookie[name]
}
