const allSelects = document.querySelectorAll('select')

Array.from(allSelects).forEach(allSelect => {
  if (allSelect.value != '') allSelect.classList.add('-has-value')
  allSelect.addEventListener('change', e => {
    if (allSelect.value != '') {
      allSelect.classList.add('-has-value')
    } else {
      allSelect.classList.remove('-has-value')
    }
  })
})
